<template>
  <l-map
    v-if="!isLoading"
    style="height: 330px; z-index: 1 !important;"
    :zoom="zoom"
    :center="center"
    @click="setPos"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="markerLatLng"></l-marker>
  </l-map>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [],
      markerLatLng: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  mounted() {
    if (
      this.$store.state.app.geotag.longitud === undefined
      || this.$store.state.app.geotag.latitud === undefined
      || this.$store.state.app.geotag.longitud === null
      || this.$store.state.app.geotag.latitud === null
    ) {
      this.load()
    } else {
      this.center = [this.$store.state.app.geotag.latitud, this.$store.state.app.geotag.longitud]
      this.markerLatLng = [this.$store.state.app.geotag.latitud, this.$store.state.app.geotag.longitud]
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations(['updateGeoTag', 'updateOficinaRenta']),
    load() {
      /* this.axios
        .get('https://ipapi.co/json')
        .then(res => {
          const { latitude } = res.data
          const { longitude } = res.data
          this.center = [latitude, longitude]
          this.markerLatLng = [latitude, longitude]

          const geotag = { longitud: longitude, latitud: latitude }

          this.updateGeoTag(geotag)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false)) */

      this.center = ['44.99588261', '-14.62325140453']
      this.markerLatLng = ['44.99588261', '-14.62325140453']
      const geotag = { longitud: '-14.62325140453', latitud: '44.99588261' }
      this.updateGeoTag(geotag)
      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    setPos(e) {
      if (!this.onlyShow) {
        const coord = e.latlng
        const latitude = coord.lat
        const longitude = coord.lng

        this.center = [latitude, longitude]
        this.markerLatLng = [latitude, longitude]

        const geotag = { longitud: longitude, latitud: latitude }

        this.updateGeoTag(geotag)
        this.callNominatum(latitude, longitude)
      }
    },
    callNominatum(lat, long) {
      this.axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`).then(res => {
        const { address } = res.data

        const json = {}
        if (address.country) {
          json.country = address.country
        }
        if (address.country_code) {
          json.country_code = address.country_code
        }
        if (address.province) {
          json.state = address.province
        }
        if (address.state) {
          json.state = address.state
        }
        if (address.city) {
          json.city = address.city
        }

        this.updateOficinaRenta(json)
      })
    },
  },
}
</script>

<style>
/*.leaflet-control-attribution a {
    text-decoration: none;
    visibility: hidden;
}
.leaflet-container .leaflet-control-attribution {
    background: #fff;
    background: rgba(255, 255, 255, 0);
    margin: 0;
}*/
</style>
