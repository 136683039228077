<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.cars')} / ${$t('menu.oficinaRenta')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'oficinas-cars-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="oficinaRenta.name"
                      :disabled="onlyShow"
                      :label="$t('lbl.oficina')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <!--<v-text-field
                      v-model="oficinaRenta.ptos_recogida"
                      :disabled="onlyShow"
                      :label="$t('menu.ptoRecogida')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>-->
                    <v-select
                      v-model="oficinaRenta.pto_recogida"
                      :disabled="onlyShow"
                      :items="ptosRecogida"
                      item-text="name"
                      item-value="id"
                      :label="$t('menu.ptoRecogida')"
                      outlined
                      dense
                      hide-details
                      @change="changePuntosRecogida"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="oficinaRenta.country"
                      :disabled="onlyShow"
                      :label="$t('lbl.country')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="oficinaRenta.state"
                      :disabled="onlyShow"
                      :label="$t('lbl.provinceEstado')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="oficinaRenta.city"
                      :disabled="onlyShow"
                      :label="$t('lbl.locality')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="oficinaRenta.direccion"
                      :disabled="onlyShow"
                      :label="$t('lbl.address')"
                      outlined
                      no-resize
                      rows="3"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!--<v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('menu.ptoRecogida') }}</strong>
                    <v-row>
                      <v-col
                        v-for="(cara, i) in ptosRecogida"
                        :key="i"
                        cols="12"
                        md="3"
                      >
                        <v-checkbox
                          v-model="modelPtosRecogida"
                          :label="cara.name"
                          :value="cara.id"
                          hide-details
                          :prepend-icon="cara.icon != null ? cara.icon : null"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row
                  no-gutters
                  class="pb-5 pt-2"
                >
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    class="pb-2 pt-3"
                  >
                    <b>{{ $t('lbl.telefonos') }}</b>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="adicionarTelefono()"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pb-5"
                  >
                    <v-divider v-if="$store.state.app.telefonos.length > 0"></v-divider>
                    <v-row
                      no-gutters
                      class="pt-5"
                    >
                      <Telefonos
                        v-for="(telefono, i) in $store.state.app.telefonos"
                        :key="i"
                        :telefono="telefono"
                        :pos="i"
                      />
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    class="pb-2 pt-3"
                  >
                    <b>{{ $t('lbl.horarios') }}</b>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="adicionarHorario()"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-divider v-if="$store.state.app.horarios.length > 0"></v-divider>
                    <Horarios
                      v-for="(horario, i) in $store.state.app.horarios"
                      :key="i"
                      :pos="i"
                      :horario="horario"
                      class="pt-2"
                    />
                  </v-col>
                </v-row>-->
              </v-col>

              <!--GEOTAG-->
              <v-col
                cols="12"
                md="8"
              >
                <v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <GeoTag v-if="!editGeoTag" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="$store.state.app.geotag.longitud"
                      disabled
                      :label="$t('lbl.longitud')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="$store.state.app.geotag.latitud"
                      disabled
                      :label="$t('lbl.latitud')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!--<v-row>
              !--TELEFONOS--
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        class="pb-2 pt-2"
                      >
                        <b>{{ $t('lbl.telefonos') }}</b>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                      >
                        <v-tooltip
                          v-if="!$store.state.app.onlyShow"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              x-small
                              color="primary"
                              @click="adicionarTelefono()"
                            >
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ icons.mdiPlus }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('btn.insert') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pb-5"
                      >
                        <v-row
                          class="pt-5 pb-5"
                        >
                          <Telefonos
                            v-for="(telefono, i) in $store.state.app.telefonos"
                            :key="i"
                            :telefono="telefono"
                            :pos="i"
                          />
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              !--HORARIOS--
              <v-col
                cols="12"
                md="8"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    class="pb-2 pt-2"
                  >
                    <b>{{ $t('lbl.horarios') }}</b>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                  >
                    <v-tooltip
                      v-if="!$store.state.app.onlyShow"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="adicionarHorario()"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <Horarios
                      v-for="(horario, i) in $store.state.app.horarios"
                      :key="i"
                      :pos="i"
                      :horario="horario"
                      class="pt-2"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>-->
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import GeoTag from '@/views/nomencladores/utils/GeoTagOficinaRenta.vue'

// import Horarios from '../utils/Horarios.vue'
// import Telefonos from '../utils/Telefonos.vue'

export default {
  components: {
    AppCardCode,
    GeoTag,

    // Telefonos,
    // Horarios,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      timezone: '',
      aeronaves: [],
      aerolineas: [],
      semanas: [],
      modelSemanas: [],
      model: {},
      showMap: true,
      loading: false,

      ptosRecogida: [],
      modelPtosRecogida: [],

      items: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('oficina-renta-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.getPtosRecogida()
    this.getItem()
    if (sessionStorage.getItem('oficina-renta-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
  },
  methods: {
    ...mapMutations(['adicionarTelefono', 'updateTelefonos', 'editOficinaRenta', 'updateGeoTag',
      'adicionarHorario', 'updateHorarios', 'setOnlyShow']),

    getItem() {
      if (sessionStorage.getItem('oficina-renta-id') !== null) {
        const id = sessionStorage.getItem('oficina-renta-id')
        this.axios
          .get(`nom_oficina_renta_cars/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data

            let country = null
            // eslint-disable-next-line camelcase
            let country_code = null
            let state = null
            let city = null
            let direccion = null
            if (this.model.country !== null) {
              // eslint-disable-next-line no-const-assign
              country = this.model.country
            }
            if (this.model.country_code !== null) {
              // eslint-disable-next-line no-const-assign, camelcase
              country_code = this.model.country_code
            }
            if (this.model.state !== null) {
              // eslint-disable-next-line no-const-assign
              state = this.model.state
            }
            if (this.model.city !== null) {
              // eslint-disable-next-line no-const-assign
              city = this.model.city
            }
            if (this.model.direccion !== null) {
              // eslint-disable-next-line no-const-assign
              direccion = this.model.direccion
            }

            const json = {
              name: this.model.name,
              pto_recogida: this.model.pto_recogida,
              country,
              country_code,
              state,
              city,
              direccion,
            }
            this.editOficinaRenta(json)
            this.updateGeoTag({ longitud: this.model.longitude, latitud: this.model.latitude })

            // this.modelPtosRecogida = this.model.ptos_recogida_id

            this.$store.state.app.geotag.longitud = this.model.longitud
            this.$store.state.app.geotag.latitud = this.model.latitud

            /* if (this.model.telefonos) {
              this.updateTelefonos(this.model.telefonos)
            } else {
              this.updateTelefonos([{ number: '' }])
            }

            if (this.model.horarios) {
              this.updateHorarios(this.model.horarios)
            } else {
              this.updateHorarios([{
                modelSemana: [0, 1, 2, 3, 4, 5, 6],
                is_time_completo: true,
                horarios: [],
              }])
            } */

            setTimeout(() => {
              this.editGeoTag = false
            }, 100)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
        this.editOficinaRenta({})

        /* this.updateTelefonos([{ number: '' }])
        this.updateHorarios([{
          modelSemana: [0, 1, 2, 3, 4, 5, 6],
          is_time_completo: true,
          horarios: [],
        }]) */
        setTimeout(() => {
          this.editGeoTag = false
        }, 100)
      }
    },
    getPtosRecogida() {
      this.axios
        .get(`nom_tags_pto_recogida_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.ptosRecogida = res.data.data
        })
    },

    save() {
      if (
        this.oficinaRenta.name
        && this.oficinaRenta.pto_recogida
        && this.oficinaRenta.country
        && this.oficinaRenta.state
        && this.oficinaRenta.city
        && this.oficinaRenta.direccion
        && this.$store.state.app.geotag.longitud
        && this.$store.state.app.geotag.latitud
      ) {
        this.loading = true
        const json = {
          name: this.oficinaRenta.name,
          pto_recogida: this.oficinaRenta.pto_recogida,
          country: this.oficinaRenta.country,
          country_code: this.oficinaRenta.country_code,
          state: this.oficinaRenta.state,
          city: this.oficinaRenta.city,
          direccion: this.oficinaRenta.direccion,
          longitud: this.$store.state.app.geotag.longitud,
          latitud: this.$store.state.app.geotag.latitud,

          // ptos_recogida: this.modelPtosRecogida,
          // telefonos: this.$store.state.app.telefonos,
          // horarios: this.$store.state.app.horarios,

          perfil_slug: sessionStorage.getItem('perfil'),
        }

        // console.log(json)

        if (sessionStorage.getItem('oficina-renta-id') === null) {
          this.axios
            .post('nom_oficina_renta_cars', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.existFlight', { numero: this.model.numero_de_vuelo }))
                }
              } else {
                sessionStorage.removeItem('oficina-renta-id')
                this.$router.push({ name: 'oficinas-cars-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`nom_oficina_renta_cars/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                sessionStorage.removeItem('oficina-renta-id')
                this.$router.push({ name: 'oficinas-cars-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    changePuntosRecogida() {
      // console.log(this.oficinaRenta.pto_recogida)
    },
  },
}
</script>
